import 'keen-slider/keen-slider.min.css'
import { css, Global } from '@obvio/app'
import { app } from '@obvio/app/App/index'

import { figtree, woodland } from '@/constants/fonts'

export default app({
  contextPaths: {
    event: '/events',
  },
  enhance: (C) => (p) =>
    (
      <>
        <C {...p} />
        <Global
          styles={css`
            :root {
              --font-family-figtree: ${figtree.style.fontFamily};
              --font-family-woodland: ${woodland.style.fontFamily};
            }
          `}
        />
      </>
    ),
})
